<template>
  <div class="nk-block-head nk-block-head-lg">
    <div class="nk-block-between">
      <div class="nk-block-head-content">
        <h4 class="nk-block-title mb-0">Account Information</h4>
        <div class="nk-block-des">
          <p class="mb-0">Manage your profile</p>
        </div>
      </div>
    </div>
  </div>
  <!-- .nk-block-head -->
  <div class="nk-block" v-if="false">
    <div class="card">
      <div class="card-inner-group">
        <div class="card-inner p-0">Profile</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
export default {
  components: {},
  setup() {
    const title = ref("Profile");
    const store = useStore();
    const Settings = computed(() => store.getters.settings);
    return {
      title,
      Settings,
    };
  },
};
</script>
